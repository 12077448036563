<template>
  <div :class="dynamicClasses">
    <i :class="iconClasses"></i>
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'Alert',
    props: {
      show: {
        type    : Boolean|String,
        default : false
      },
      type: {
        type    : String,
        default : 'info'
      },
      text: {
        type    : String,
        default : ''
      },
      size: {
        type    : String,
        default : ''
      }
    },
    computed: {
      dynamicClasses ( ) {
        return [
          this.show  ? 'show' : 'hide',
          this.type,
          this.size
        ].join( ' ' ).split( ' ' );
      },
      iconClasses ( ) {
        if ( this.type === 'success' && this.size === 'big' )
          return 'icon far fa-thumbs-up';
        if ( this.type === 'error' && this.size === 'big' )
          return 'icon far fa-thumbs-down';
        if ( this.type === 'info'    ) return 'icon fas fa-info-circle';
        if ( this.type === 'success' ) return 'icon fas fa-check-square';
        if ( this.type === 'warning' ) return 'icon fas fa-exclamation-triangle';
        if ( this.type === 'error'   ) return 'icon fas fa-times';
      }
    }
  }
</script>

<style scoped lang="scss">
  .hide, .show {
    font-size       : 12px;
    padding         : 5px 0;
    margin          : 0;
    cursor          : default;
    width           : 100%;
    text-align      : center;
    transition      : 300ms;
    display         : flex;
    justify-content : center;
    align-items     : center;
  }

  .hide {
    font-size       : 0;
    margin          : 0;
    padding         : 0;
    opacity         : 0;
  }

  .show {
    opacity         : 1;
  }

  .big {
    background      : #ff000020;
    display         : flex;
    flex-direction  : column;
    justify-content : center;
    align-items     : center;
    padding         : 2rem;
    border-radius   : 10px;
    height          : 100%;
    text-align      : center;

    .icon {
      font-size     : 5rem;
      padding       : 1rem;
    }

    a {
      cursor        : pointer;
    }

    &.Error {
      background    : #f8d7da;
      color         : #721c24;
    }

    &.Success {
      background    : #d4edda;
      color         : #155724;
    }

    p {
      padding       : 10px 0;
    }
  }

  .icon {
    padding         : .5rem;
  }

  .info {
    background      : #cce5ff;
    color           : #004085;
    border          : 1px solid #b8daff;
  }

  .success {
    background      : #d4edda;
    color           : #155724;
    border          : 1px solid #c3e6cb;
  }

  .warning {
    background      : #fff3cd;
    color           : #856404;
    border          : 1px solid #ffeeba;
  }

  .error {
    background      : #f8d7da;
    color           : #721c24;
    border          : 1px solid #f5c6cb;
  }
</style>